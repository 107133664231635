<template>
    <div class="saas_main">
        <a class="top_banner" href="https://www.afterdoc.co.kr/contact/home" target="_blank" >
            <div class="top_banner_txt">애프터닥이 궁금하신가요? 제안서를 바로 받아보세요</div>
            <div>
                <img src="@/assets/img/saas/chevron.png" alt="">
            </div>
            <div class="contact_btn">제안서 받아보기</div>
        </a>
        <div class="header">
            <div class="saas_header">
                <div class="header_left">
                    <a href="#" class="header_logo">
                        <img src="@/assets/img/saas/logo_v2.svg" alt="애프터닥 로고">
                    </a>
                    <div class="header_menu">
                        <a href="https://medipal.co.kr/" target="_blank">회사소개</a>
                        <a href="https://blog.afterdoc.co.kr/" target="_blank">블로그</a>
                        <div>
                            <input type="checkbox" id="faq_toggle_pc" class="faq_toggle"/>
                            <label for="faq_toggle_pc" class="faq_label">자주묻는질문</label>
                            <div class="faq_overlay"></div>
                            <div class="faq_section">
                                <div class="faq_title flex_aic_spb">
                                    <div></div>
                                    <div class="">자주묻는질문</div>
                                    <label for="faq_toggle_pc" class="close_faq_btn flex_aic_jcc">
                                        <span></span>
                                        <span></span>
                                    </label>
                                </div>
                                <div class="faq_content">
                                    <div class="faq_item">
                                        <input type="checkbox" id="faq_1_pc" class="faq_toggle">
                                        <label for="faq_1_pc" class="faq_label">
                                            <div class="faq_question flex_aic_spb">
                                                <div><span class="main_blue_ft">[도입 문의]</span> 서비스/가격 등 궁금한 점이 있습니다.</div>
                                                <div class="arrow_wrap flex_aic_jcc"><span class="arrow"></span></div>
                                            </div>
                                            <div class="faq_answer">
                                                <div class="faq_answer_row1">애프터닥은 도입을 원하시는 병·의원의 규모와 진료 분야에 따라 커스터마이징 되어 제공되는 솔루션입니다. 도입을 원하신다면 방문하여 상담을 통해 서비스 방향을 결정하고 가격이 책정됩니다.</div><br>
                                                아래 단계에 따라 문의 주신다면 확인 후 빠르게 답변드리도록 하겠습니다.
                                                <br>
                                                1. 애프터닥 홈페이지 상단에 있는 <span class="main_blue_ft">[무료상담 요청]</span>을 통해 문의해 주세요.<br>
                                                2. 홈페이지 내 온라인 문의는 연락처를 정확하게 입력해 주셔야 회신드릴 수 있습니다.<br>
                                                3. 문의사항은 담당자 확인 후 영업일 기준 0~1일 내 답변을 드립니다.
                                            </div>
                                        </label>
                                    </div>
                                    <div class="faq_item">
                                        <input type="checkbox" id="faq_2_pc" class="faq_toggle">
                                        <label for="faq_2_pc" class="faq_label">
                                            <div class="faq_question flex_aic_spb">
                                                <div><span class="main_blue_ft">[도입 문의]</span> 애프터닥을 이용하고 싶습니다.</div>
                                                <div class="arrow_wrap flex_aic_jcc"><span class="arrow"></span></div>
                                            </div>
                                            <div class="faq_answer">
                                                <div class="faq_answer_row1">
                                                    애프터닥에 관심 가져 주셔서 감사합니다.
                                                    애프터닥의 도입은 홈페이지 내 <span class="main_blue_ft">[무료상담 요청]</span>을 통해 신청하실 수 있습니다.
                                                    아래 단계에 따라 문의 주신다면 확인 후 빠르게 답변드리도록 하겠습니다.
                                                </div><br>
                                                1. 애프터닥 홈페이지 상단에 있는 <span class="main_blue_ft">[무료상담 요청]</span>을 눌러 문의해 주세요. <br>
                                                2. 홈페이지 내 온라인 문의는 연락처를 정확하게 입력해 주셔야 회신드릴 수 있습니다. <br>
                                                3. 문의사항은 담당자 확인 후 영업일 기준 0~1일 내 답변을 드립니다.
                                            </div>
                                        </label>
                                    </div>
                                    <div class="faq_item">
                                        <input type="checkbox" id="faq_3_pc" class="faq_toggle">
                                        <label for="faq_3_pc" class="faq_label">
                                            <div class="faq_question flex_aic_spb">
                                                <div><span class="main_blue_ft">[서비스 문의]</span> 애프터닥 소프트웨어는 어떻게 구성되어 있나요?</div>
                                                <div class="arrow_wrap flex_aic_jcc"><span class="arrow"></span></div>
                                            </div>
                                            <div class="faq_answer">
                                                <div class="faq_answer_row1">애프터닥에서 제공하는 소프트웨어는 병·의원에 개별적으로 제공되는 플랫폼을 관리, 운영할 수 있는 소프트웨어입니다. 제공받으신 플랫폼은 해당 병·의원에 내원한 환자들만이 가입이 가능하며, 환자분들은 스마트폰 Application을 통해 그리고 병·의원에서는 관리용 PC프로그램을 통해 사용하실 수 있습니다. </div><br>
                                                더 궁금하신 사항이 있으시다면 홈페이지 상단의 <span class="main_blue_ft">[무료상담 요청]</span>을 통해 남겨주세요.<div class="br"></div>
                                                확인 후 빠르게 담당자가 답변 드리도록 하겠습니다.
                                            </div>
                                        </label>
                                    </div>
                                    <div class="faq_item">
                                        <input type="checkbox" id="faq_4_pc" class="faq_toggle">
                                        <label for="faq_4_pc" class="faq_label">
                                            <div class="faq_question flex_aic_spb">
                                                <div><span class="main_blue_ft">[서비스 문의]</span> 기존 EMR/CRM 프로그램을 사용중이었는데, 무엇이 다른가요?</div>
                                                <div class="arrow_wrap flex_aic_jcc"><span class="arrow"></span></div>
                                            </div>
                                            <div class="faq_answer">
                                                <div class="faq_answer_row1">병·의원에서 기존 사용하시던 EMR/CRM 프로그램과 애프터닥에서 제공하는 소프트웨어는 별도로 설치 운영됩니다. 애프터닥의 소프트웨어는 병·의원의 환자 자산을 확보하기 위한 플랫폼을 운영하기 위한 소프트웨어로써, 기록을 위한 EMR/CRM과는 다른 목적을 가지고 있습니다. </div><br>
                                                더 궁금하신 사항이 있으시다면 홈페이지 상단의 <span class="main_blue_ft">[무료상담 요청]</span>을 남겨주세요. <br> 
                                                확인 후 빠르게 담당자가 연락드리도록 하겠습니다.
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="header_right flex_aic">
                    <a href="https://www.afterdoc.co.kr/contact/home" class="contact_btn" target="_blank">무료상담 요청</a>

                    <div class="header_ham_menu">
                        <div class="ham_btn_wrap flex_aic_jcc">
                            <input type="checkbox" id="menu_toggle" class="menu_toggle"/>
                            <label for="menu_toggle" class="menu_btn">
                                <span></span>
                                <span></span>
                                <span></span>
                            </label>
                            <div class="saas_sitemap">
                                <a href="https://medipal.co.kr/" target="_blank">회사소개</a>
                                <a href="https://blog.afterdoc.co.kr/" target="_blank">블로그</a>
                                <div>
                                    <input type="checkbox" id="faq_toggle_mo" class="faq_toggle"/>
                                    <label for="faq_toggle_mo" class="faq_label">자주묻는질문</label>
                                    <div class="faq_overlay"></div>
                                    <div class="faq_section">
                                        <div class="faq_title flex_aic_spb">
                                            <div></div>
                                            <div class="">자주묻는질문</div>
                                            <label for="faq_toggle_mo" class="close_faq_btn flex_aic_jcc">
                                                <span></span>
                                                <span></span>
                                            </label>
                                        </div>
                                        <div class="faq_content">
                                            <div class="faq_item">
                                                <input type="checkbox" id="faq_1_mo" class="faq_toggle">
                                                <label for="faq_1_mo" class="faq_label">
                                                    <div class="faq_question flex_aic_spb">
                                                        <div><span class="main_blue_ft">[도입 문의]</span> 서비스/가격 등 궁금한 점이 있습니다.</div>
                                                        <div class="arrow_wrap flex_aic_jcc"><span class="arrow"></span></div>
                                                    </div>
                                                    <div class="faq_answer">
                                                        <div class="faq_answer_row1">애프터닥은 도입을 원하시는 병·의원의 규모와 진료 분야에 따라 커스터마이징 되어 제공되는 솔루션입니다. 도입을 원하신다면 방문하여 상담을 통해 서비스 방향을 결정하고 가격이 책정됩니다.</div><br>
                                                        아래 단계에 따라 문의 주신다면 확인 후 빠르게 답변드리도록 하겠습니다.
                                                        <br>
                                                        1. 애프터닥 홈페이지 상단에 있는 <span class="main_blue_ft">[무료상담 요청]</span>을 통해 문의해 주세요.<br>
                                                        2. 홈페이지 내 온라인 문의는 연락처를 정확하게 입력해 주셔야 회신드릴 수 있습니다.<br>
                                                        3. 문의사항은 담당자 확인 후 영업일 기준 0~1일 내 답변을 드립니다.
                                                    </div>
                                                </label>
                                            </div>
                                            <div class="faq_item">
                                                <input type="checkbox" id="faq_2_mo" class="faq_toggle">
                                                <label for="faq_2_mo" class="faq_label">
                                                    <div class="faq_question flex_aic_spb">
                                                        <div><span class="main_blue_ft">[도입 문의]</span> 애프터닥을 이용하고 싶습니다.</div>
                                                        <div class="arrow_wrap flex_aic_jcc"><span class="arrow"></span></div>
                                                    </div>
                                                    <div class="faq_answer">
                                                        <div class="faq_answer_row1">
                                                            애프터닥에 관심 가져 주셔서 감사합니다.
                                                            애프터닥의 도입은 홈페이지 내 <span class="main_blue_ft">[무료상담 요청]</span>을 통해 신청하실 수 있습니다.
                                                            아래 단계에 따라 문의 주신다면 확인 후 빠르게 답변드리도록 하겠습니다.
                                                        </div><br>
                                                        1. 애프터닥 홈페이지 상단에 있는 <span class="main_blue_ft">[무료상담 요청]</span>을 눌러 문의해 주세요. <br>
                                                        2. 홈페이지 내 온라인 문의는 연락처를 정확하게 입력해 주셔야 회신드릴 수 있습니다. <br>
                                                        3. 문의사항은 담당자 확인 후 영업일 기준 0~1일 내 답변을 드립니다.
                                                    </div>
                                                </label>
                                            </div>
                                            <div class="faq_item">
                                                <input type="checkbox" id="faq_3_mo" class="faq_toggle">
                                                <label for="faq_3_mo" class="faq_label">
                                                    <div class="faq_question flex_aic_spb">
                                                        <div><span class="main_blue_ft">[서비스 문의]</span> 애프터닥 소프트웨어는 어떻게 구성되어 있나요?</div>
                                                        <div class="arrow_wrap flex_aic_jcc"><span class="arrow"></span></div>
                                                    </div>
                                                    <div class="faq_answer">
                                                        <div class="faq_answer_row1">애프터닥에서 제공하는 소프트웨어는 병·의원에 개별적으로 제공되는 플랫폼을 관리, 운영할 수 있는 소프트웨어입니다. 제공받으신 플랫폼은 해당 병·의원에 내원한 환자들만이 가입이 가능하며, 환자분들은 스마트폰 Application을 통해 그리고 병·의원에서는 관리용 PC프로그램을 통해 사용하실 수 있습니다. </div><br>
                                                        더 궁금하신 사항이 있으시다면 홈페이지 상단의 <span class="main_blue_ft">[무료상담 요청]</span>을 통해 남겨주세요. <br >
                                                        확인 후 빠르게 담당자가 답변 드리도록 하겠습니다.
                                                    </div>
                                                </label>
                                            </div>
                                            <div class="faq_item">
                                                <input type="checkbox" id="faq_4_mo" class="faq_toggle">
                                                <label for="faq_4_mo" class="faq_label">
                                                    <div class="faq_question flex_aic_spb">
                                                        <div><span class="main_blue_ft">[서비스 문의]</span> 기존 EMR/CRM 프로그램을 사용중이었는데, 무엇이 다른가요?</div>
                                                        <div class="arrow_wrap flex_aic_jcc"><span class="arrow"></span></div>
                                                    </div>
                                                    <div class="faq_answer">
                                                        <div class="faq_answer_row1">병·의원에서 기존 사용하시던 EMR/CRM 프로그램과 애프터닥에서 제공하는 소프트웨어는 별도로 설치 운영됩니다. 애프터닥의 소프트웨어는 병·의원의 환자 자산을 확보하기 위한 플랫폼을 운영하기 위한 소프트웨어로써, 기록을 위한 EMR/CRM과는 다른 목적을 가지고 있습니다. </div><br>
                                                        더 궁금하신 사항이 있으시다면 홈페이지 상단의 <span class="main_blue_ft">[무료상담 요청]</span>을 남겨주세요. <br>
                                                        확인 후 빠르게 담당자가 연락드리도록 하겠습니다.
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section class="section_1">
            <div class="section_wrap">
                <div class="section_title">
                    <span class="main_blue_ft">체계적 환자관리가<br class="pc_hide laptop_hide mo_show"> 최고의 마케팅입니다</span><br>
                    재진마케팅 솔루션, 애프터닥
                </div>
                <div class="section_sub_title mgt_10">
                    애프터닥 사용 병·의원은 도입 3개월 후<br><span class="main_blue_ft">평균 매출 19.7%, 고객 재방문 2.8배 상승</span> 했습니다.
                </div>
                <div class="flex_aic_jcc mgt_20">
                    <a href="https://www.afterdoc.co.kr/contact/home" class="contact_btn" target="_blank">무료상담 요청</a>
                </div>
                <div>
                    <img src="@/assets/img/saas/pc/01.png" class="pc" alt="">
                    <img src="@/assets/img/saas/mobile/01.png" class="mo" alt="">
                </div>
            </div>
        </section>

        <section class="section_2">
            <div class="section_wrap">
                <div class="section_title">
                    방문한 환자들의 이탈을 막아야<br class="pc_hide">
                    <span class="main_blue_ft">
                        매출이 다음 단계로 상승</span>합니다
                </div>
                <div class="section_sub_title mgt_10">
                    대다수의 병원이 방문환자 중 <br class="mo_show">40%의 환자들로만 운영되고 있습니다.<br><span class="main_blue_ft">구환 관리를 통해 이탈된 환자 중 10%만 재내원해도<br class="mo_show"> 기존 대비 유입환자는 15% 증가합니다.</span>
                </div>
                <div>
                    <img src="@/assets/img/saas/pc/02.png" class="pc" alt="">
                    <img src="@/assets/img/saas/mobile/02.png" class="mo" alt="">
                </div>
            </div>
        </section>

        <section class="section_3">
            <div class="section_wrap">
                <div class="section_title">
                    그러나, 바쁜 진료 현장에서<br class="pc_hide"> 환자관리는 쉽지 않습니다
                </div>
                <div class="item_point_wrap flex_aic_jcc gap_25 mgt_32">
                    <div class="item_point">
                        <img src="@/assets/img/saas/pc/03_1.png" class="pc" alt="">
                        <img src="@/assets/img/saas/mobile/03_1.png" class="mo"  alt="">
                        <div class="item_txt">
                            <p>직원의 업무 숙련도에 따른</p>
                            <b class="main_blue_ft">환자관리 일관성 저하</b>
                        </div>
                    </div>
                    <div class="item_point">
                        <img src="@/assets/img/saas/pc/03_2.png" class="pc" alt="">
                        <img src="@/assets/img/saas/mobile/03_2.png" class="mo"  alt="">
                        <div class="item_txt">
                            <b class="main_blue_ft">갈수록 효율이 떨어지는</b>
                            <p>유사한 내용의 광고 문자</p>
                        </div>
                    </div>
                    <div class="item_point">
                        <img src="@/assets/img/saas/pc/03_3.png" class="pc" alt="">
                        <img src="@/assets/img/saas/mobile/03_3.png" class="mo"  alt="">
                        <div class="item_txt">
                            <p>만만치 않은</p>
                            <b class="main_blue_ft">메시지 발송 비용</b>
                        </div>
                    </div>
                    <div class="item_point">
                        <img src="@/assets/img/saas/pc/03_4.png" class="pc" alt="">
                        <img src="@/assets/img/saas/mobile/03_4.png" class="mo"  alt="">
                        <div class="item_txt">
                            <p>고객분석 난해함으로</p>
                            <b class="main_blue_ft">감에 의존한 경영</b>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section_4">
            <div class="section_wrap">
                <div class="section_title">
                    재진 마케팅 솔루션,<br class="mo_show"> 애프터닥은<br class="pc_show laptop_show mo_hide">
                    지난 3년간 <br class="pc_hide laptop_hide mo_show"><span class="main_blue_ft">100개+ 병원</span>에서 성과로 검증되었습니다
                </div>
                <div class="item_point_wrap flex gap_20 mgt_32">
                    <div class="flex_aic item_point">
                        <div>
                            애프터닥을 도입한 병원의<br class="pc_show"> 고객은<br class="pc_hide">
                            <b> 도입 전보다<br class="pc_show"> 평균 2.8배 더 재방문</b>
                            합니다.
                            <small class="mgt_20"><br>*애프터닥 도입 전체 병원 전, 후 3개월 실데이터 비교</small>
                        </div>
                        <img src="@/assets/img/saas/pc/04_2.png" class="pc" alt="">
                        <img src="@/assets/img/saas/mobile/04_2.png" class="mo" alt="">
                    </div>
                    <div class="flex_aic item_point">
                        <div>
                            애프터닥을 도입한 병원은<br class="pc_show laptop_show">
                            <b> 도입 전보다 평균 매출이<br class="pc_show"> 19.7%</b>
                            상승했습니다.
                            <br>
                            <small class="mgt_20">*애프터닥 도입 비급여 항목 중심 운영 병·의원 실데이터 비교</small>
                        </div>
                        <img src="@/assets/img/saas/pc/04_1.png" class="pc" alt="">
                        <img src="@/assets/img/saas/mobile/04_1.png" class="mo" alt="">
                    </div>
                </div>
            </div>
        </section>

        <div class="service_review main_blue_bg">
            <div class="section_title">
                원장님들의 <br class="mo_show">생생한 후기를 확인해 보세요
            </div>

            <div class="review_belt_wrap flex gap_40 mgt_32">
                <div class="review_item flex_column">
                    <div class="review_txt">
                        수많은 치료프로그램에 맞춰 상담 프로세스를 구축하고, 자동으로 나가게끔까지 만들다 보니 더이상 불안해 할 이유가 없어졌습니다.
                    </div>
                    <div class="review_writer flex_aic_spb">
                        <div>경기 성남시</div>
                        <div>L 피부과 원장님</div>
                    </div>
                </div>
                <div class="review_item flex_column">
                    <div class="review_txt">
                        환자분들에게 비용 문의가 들어왔을 때, 단순한 가격 안내를 넘어 환자 상황에 맞게 상담하고 추천해줄수 있게 되니까 만족도가 올라가고 매출이 증가하는게 피부로 느껴집니다.
                    </div>
                    <div class="review_writer flex_aic_spb">
                        <div>서울 강남구</div>
                        <div>B 뷰티의원 원장님</div>
                    </div>
                </div>
                <div class="review_item flex_column">
                    <div class="review_txt">
                        받은 수술에 맞춰 관리메세지를 일일이 보내는 걸 계속 시도하다가, 결국 애프터닥으로 해결하고 나니 한결 속이 시원합니다.
                    </div>
                    <div class="review_writer flex_aic_spb">
                        <div>서울 용산구</div>
                        <div>K 성형외과 원장님</div>
                    </div>
                </div>
                <div class="review_item flex_column">
                    <div class="review_txt">
                        초기상담의 어려움 때문에 놓치고 있는 신규환자들이 많아서 애프터닥을 도입했는데 내원율이 많이 올라갔네요. 비용대비 효과로 볼 때 너무 좋습니다.
                    </div>
                    <div class="review_writer flex_aic_spb">
                        <div>서울 강남구</div>
                        <div>T 뷰티의원 원장님</div>
                    </div>
                </div>
                <div class="review_item flex_column">
                    <div class="review_txt">
                        도수치료처럼 꾸준한 내원이 필요한 경우, 환자 상태별로 지속적인 관리가 필요한데 애프터닥 도입 후 치료실 예약율이 확연히 증가했습니다.
                    </div>
                    <div class="review_writer flex_aic_spb">
                        <div>대전광역시</div>
                        <div>B 신경외과 원장님</div>
                    </div>
                </div>
                <div class="review_item flex_column">
                    <div class="review_txt">
                        환자들에게 와야할 때, 추가로 약을 받아야 할 때 맞춰서 빠짐없이 안내가 나가니까 새어나가는 매출이 많이 줄어 좋습니다.
                    </div>
                    <div class="review_writer flex_aic_spb">
                        <div>대구광역시</div>
                        <div>C 한의원 원장님</div>
                    </div>
                </div>
                <div class="review_item flex_column">
                    <div class="review_txt">
                        매번 교육하기 힘들었는데, 애프터닥 도입이후에 누가 상담해도 상담퀄리티가 괜찮아지는 것 같아 너무 만족스럽습니다.
                    </div>
                    <div class="review_writer flex_aic_spb">
                        <div>서울 서초구</div>
                        <div>B 뷰티의원 원장님</div>
                    </div>
                </div>
                <div class="review_item flex_column">
                    <div class="review_txt">
                        수많은 치료프로그램에 맞춰 상담 프로세스를 구축하고, 자동으로 나가게끔까지 만들다 보니 더이상 불안해 할 이유가 없어졌습니다.
                    </div>
                    <div class="review_writer flex_aic_spb">
                        <div>경기 성남시</div>
                        <div>L 피부과 원장님</div>
                    </div>
                </div>
                <div class="review_item flex_column">
                    <div class="review_txt">
                        환자분들에게 비용 문의가 들어왔을 때, 단순한 가격 안내를 넘어 환자 상황에 맞게 상담하고 추천해줄수 있게 되니까 만족도가 올라가고 매출이 증가하는게 피부로 느껴집니다.
                    </div>
                    <div class="review_writer flex_aic_spb">
                        <div>서울 강남구</div>
                        <div>B 뷰티의원 원장님</div>
                    </div>
                </div>
                <div class="review_item flex_column">
                    <div class="review_txt">
                        받은 수술에 맞춰 관리메세지를 일일이 보내는 걸 계속 시도하다가, 결국 애프터닥으로 해결하고 나니 한결 속이 시원합니다.
                    </div>
                    <div class="review_writer flex_aic_spb">
                        <div>서울 용산구</div>
                        <div>K 성형외과 원장님</div>
                    </div>
                </div>
                <div class="review_item flex_column">
                    <div class="review_txt">
                        초기상담의 어려움 때문에 놓치고 있는 신규환자들이 많아서 애프터닥을 도입했는데 내원율이 많이 올라갔네요. 비용대비 효과로 볼 때 너무 좋습니다.
                    </div>
                    <div class="review_writer flex_aic_spb">
                        <div>서울 강남구</div>
                        <div>T 뷰티의원 원장님</div>
                    </div>
                </div>
                <div class="review_item flex_column">
                    <div class="review_txt">
                        도수치료처럼 꾸준한 내원이 필요한 경우, 환자 상태별로 지속적인 관리가 필요한데 애프터닥 도입 후 치료실 예약율이 확연히 증가했습니다.
                    </div>
                    <div class="review_writer flex_aic_spb">
                        <div>대전광역시</div>
                        <div>B 신경외과 원장님</div>
                    </div>
                </div>
                <div class="review_item flex_column">
                    <div class="review_txt">
                        환자들에게 와야할 때, 추가로 약을 받아야 할 때 맞춰서 빠짐없이 안내가 나가니까 새어나가는 매출이 많이 줄어 좋습니다.
                    </div>
                    <div class="review_writer flex_aic_spb">
                        <div>대구광역시</div>
                        <div>C 한의원 원장님</div>
                    </div>
                </div>
                <div class="review_item flex_column">
                    <div class="review_txt">
                        매번 교육하기 힘들었는데, 애프터닥 도입이후에 누가 상담해도 상담퀄리티가 괜찮아지는 것 같아 너무 만족스럽습니다.
                    </div>
                    <div class="review_writer flex_aic_spb">
                        <div>서울 서초구</div>
                        <div>B 뷰티의원 원장님</div>
                    </div>
                </div>
            </div>
        </div>

        <section class="section_5">
            <div class="section_wrap">
                <p class="title_point main_blue_ft txt_center"><b>병원 환자관리 자동화</b></p>
                <div class="section_title mgt_10">
                    단 한 번의 클릭으로<br class="mo_show"> 모든 내원 환자에게<br class="pc_show laptop_show">
                    맞춤형 환자관리가<br class="mo_show"> 자동으로 진행돼요
                </div>
                <div class="item_point_wrap flex_aic gap_40 mgt_32">
                    <div class="video_part">
                        <video src="@/assets/img/saas/pc/comp.mp4"
                            autoplay muted loop playsinline
                        >
                        </video>
                    </div>
                    <div class="item_point">
                        <b>진행한 치료별로 맞춤형 예후관리 메시지를 빠짐없이 전달하고,<br class="pc_show laptop_show mo_hide"> 치료주기에 맞춰 재내원 유도를 진행하는 체계적인 환자관리를 가능하게 합니다.</b>
                        <br>
                        <br>
                        시술/수술/상황별로 성과가 검증된 <br class="mo_show">상담·마케팅 템플릿을 <br class="pc_show">제공해드려요.
                        
                    </div>
                </div>
            </div>
        </section>
        
        <section class="section_6">
            <div class="section_wrap">
                <p class="title_point main_blue_ft txt_center"><b>상담 채널 일원화</b></p>
                <div class="section_title mgt_10">
                    운영중인 채널들의<br class="mo_show"> 흩어져 있는 상담을<br class="pc_show  laptop_show mo_hide">
                    <span class="main_blue_ft"> 애프터닥 하나로 관리</span>할 수 있어요  
                </div>
                <div class="section_sub_title mgt_20">
                    카카오톡, 인스타그램 등 다양한 채널로 들어오는 상담들을<br class="pc_hide laptop_hide"> 하나의 화면에서 관리할 수 있어요. <br class="pc_show laptop_show mo_show">
                    <b>관리채널 통합으로 업무효율성이 증가</b>되고, <br class="pc_hide laptop_hide">분산된 채널을 놓치지 않고 관리할 수 있어요.
                </div>
            
                <div class="item_point">
                    <img src="@/assets/img/saas/pc/07.png" class="pc" alt="">
                    <img src="@/assets/img/saas/mobile/07.png" class="mo" alt="">
                </div>

                <p class="item_info txt_center mgt_32">
                    ※카카오톡 외 연동은 연내 순차적으로 업데이트 됩니다
                </p>
            </div>
        </section>
        
        <section class="section_7">
            <div class="section_wrap">
                <p class="title_point main_blue_ft txt_center"><b>상담을 언제나 일관되게</b></p>
                <div class="section_title mgt_10">
                    우리 병원에  최적화된 상담매뉴얼을<br class="pc_show laptop_show mo_hide">
                    병원 직원 누구나 쉽게 활용할 수 있어요
                </div>
                <div class="section_sub_title mgt_20">
                    우리 병원의 시술/수술 프로그램에 맞춘 상담 매뉴얼을 기준으로<br class="pc_show laptop_show mo_hide">
                    환자들의 다양한 문의에 대해 <br class="mo_show">일관되게 응대할 수 있어요.
                </div>
            
                <div class="item_point">
                    <img src="@/assets/img/saas/pc/08.png" class="pc" alt="">
                    <img src="@/assets/img/saas/mobile/08.png" class="mo" alt="">
                </div>
            </div>
            
        </section>

        <section class="section_8">
            <div class="section_wrap">
                <p class="title_point main_blue_ft txt_center"><b>단체 문자는 이제 그만</b></p>
                <div class="section_title mgt_10">
                    치료 니즈를 고려한 <br class="pc_hide laptop_hide">맞춤형 마케팅을 보낼 수 있어요
                </div>
                <div class="section_sub_title mgt_20">
                    환자들이 가진 치료 니즈에 맞춰 <br class="pc_hide laptop_hide">보다 <br class="mo_show">효과적인 타겟 마케팅이 가능합니다.<br>
                    보내진 마케팅 메시지에 대해<br class="mo_show"> 고객들의 반응을 확인하고<br class="pc_hide laptop_hide"> 개선할 수 있습니다.
                </div>
            
                <div class="item_point">
                    <img src="@/assets/img/saas/pc/09.png" class="pc" alt="">
                    <img src="@/assets/img/saas/mobile/09.png" class="mo" alt="">
                </div>
            </div>
        </section>

        <div class="divide">
            <div class="section_title">
                <span class="main_blue_ft">
                    병원에 새로운 기회를 창출하는<br>
                    애프터닥만의 기능!
                </span>                
            </div>
        </div>

        <section class="section_9">
            <div class="section_wrap">
                <p class="title_point main_blue_ft txt_center"><b>외국인 환자도 걱정없이</b></p>
                <div class="section_title mgt_10">
                    외국인 환자도 상담실장이<br class="mo_show"> 직접 응대 가능                
                </div>
                <div class="section_sub_title mgt_20">
                    치료명부터 상담용어까지, 지속적으로 발전하는<br>
                    의료 상담 맞춤 번역 기능이 제공됩니다.
                </div>
            
                <div class="item_point flag">
                    <img src="@/assets/img/saas/pc/10_1.png" class="pc" alt="">
                    <img src="@/assets/img/saas/mobile/10_1.png" class="mo" alt="">
                </div>
                <div class="item_point">
                    <img src="@/assets/img/saas/pc/10_2.png" class="pc" alt="">
                    <img src="@/assets/img/saas/mobile/10_2.png" class="mo" alt="">
                </div>
            </div>
        </section>

        <section class="section_10">
            <div class="section_wrap">
                <p class="title_point main_blue_ft txt_center"><b>우리 병원의 강점을 더욱 강화할 수 있도록</b></p>
                <div class="section_title mgt_10">
                    실제 경영 환경을 분석해<br>
                    그에 맞춘 경영 분석 시스템 제공                
                </div>
                <div class="section_sub_title mgt_20">
                    향후 진료 방향을 결정할 환자들의 최근 유입 경로 변화, <br class="pc_hide laptop_hide">방문 목적 경향 등을 파악하고 계신가요? <br class="pc_show laptop_show"><span class="main_blue_ft">고객 행동 데이터에 담긴 의미와 개선점을 구체적으로 알려주어</span> 효과적 경영 의사결정을 지원합니다.
                </div>
            
                <div class="item_point flag">
                    <img src="@/assets/img/saas/pc/11.png" class="pc" alt="">
                    <img src="@/assets/img/saas/mobile/11.png" class="mo" alt="">
                </div>
            </div>
        </section>

        <div class="consultation_wrap main_blue_bg">
            <div class="section_title">
                10분 간단 상담을 통해<br>
                우리 병원 맞춤형 도입 전략을 알아보세요           
            </div>
            <div class="section_sub_title mgt_10">
                애프터닥으로 앞서나가는 병원이 되세요.
            </div>
            <div class="flex_aic_jcc mgt_20">
                <a href="https://www.afterdoc.co.kr/contact/home" class="contact_btn main_blue_ft" target="_blank">
                    지금 무료상담 신청
                </a>
            </div>
        </div>

        <div class="footer">
            <div class="section_wrap flex_column">
                <div class="flex_aic gap_20">
                    <img src="@/assets/img/saas/logo/medipal.png" alt="">
                    <p>㈜메디팔</p>
                </div>
                <div class="office_info flex_column gap_10 mgt_20">
                    <div class="flex gap_10 row1">
                        <p>전화: 070-5180-4070</p>
                        <p>이메일: contact@medipal.co.kr</p>
                        <p>대표: 강종일</p>
                    </div>
                    <div class="flex row2">
                        <p>주소: 서울시 강남구 테헤란로 420, 16층 (KT 선릉타워 West)</p>
                    </div>
                    <div class="flex row2">
                        <p>사업자 등록번호: 494-88-01653</p>
                    </div>
                </div>
                <div class="copyright">Copyright 2025. Medipal Inc. All Rights Reserved.</div>
            </div>
        </div>

    </div>
</template>


<style>
    @import url(@/assets/css/SaaSMain.css);
</style>

<script>

export default {
    
}
</script>