<template>
    <div style="height: calc(100% - 8px);">
        <iframe style='border: none;' width='100%' height='100%'
            src='https://walla.my/survey/cTzzJm58LWN95NTiKHe5' allowfullscreen>
        </iframe>
    </div>
</template>


<script>
export default {
    
}
</script>