import { createWebHistory, createRouter } from "vue-router"

// import Main from "../views/Main/MainView.vue"
import Event_AL from "../views/Event/AL/Event_AL.vue"
import Event_Message from '../views/Event/Message/Event_Message.vue'
import IntroVideo from '../views/IntroVideo/IntroVideo.vue'
import ContactPage from "@/views/Share/Contact/ContactPage.vue"

import RegistThirdParty from "@/views/RegistThirdParty/RegistThirdParty.vue"
  import RegistMain from "@/views/RegistThirdParty/RegistMain.vue"
  import RegistPreinform from '@/views/RegistThirdParty/Preinform/RegistPreinform.vue'
  import RegistChannels from "@/views/RegistThirdParty/RegistChannels/RegistChannels.vue"
  import RegistKakaoConsult from "@/views/RegistThirdParty/RegistChannels/KakaoConsult/RegistKakaoConsult.vue"
  import RegistKakaoFriend from "@/views/RegistThirdParty/RegistChannels/RegistKakaoFriend/RegistKakaoFriend.vue"

import SaaSMain from "@/views/SaaSMain/SaaSMain.vue"

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: SaaSMain,
      name: "SaaSMain",
    },
    {
      path: "/contact/:key?",
      component: ContactPage,
      name: "ContactPage",
    },
    {
      path: "/event/al",
      component: Event_AL,
      name: "Event_AL"
    },
    {
      path: '/event/m',
      component: Event_Message,
      name: 'Event_Message'
    },
    {
      path: '/intro',
      component: IntroVideo,
      name: "IntroVideo"
    },
    {
      path: '/regist',
      component: RegistThirdParty,
      name: "RegistThirdParty",
      children: [
        {
          path: '/regist',
          component: RegistMain,
          name:'RegistMain'
        },
        {
          path: '/regist/preinform',
          component: RegistPreinform,
          name: 'RegistPreinform'
        },
        {
          path: '/regist/channels',
          component: RegistChannels,
          name: 'RegistChannels',
        },
        {
          path: '/regist/kakaoconsult',
          component: RegistKakaoConsult,
          name: 'RegistKakaoConsult',
        },
        {
          path: '/regist/kakaofriend',
          component: RegistKakaoFriend,
          name:'RegistKakaoFriend'
        },
      ]
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/'
    }
  ],
})

export { router }
